import request from './axios'
import api from './api'

// 登录
export function login (data: object) {
  return request({
    url: api.user.login,
    method: 'post',
    data
  })
}

// 刷新token
export function refreshToken (data: object) {
  return request({
    url: api.user.refreshToken,
    method: 'post',
    data,
    loading: false
  })
}

// 获取菜单
export function getMenu (params: object) {
  return request({
    url: api.user.getSitemenu,
    method: 'get',
    params
  })
}

// 获取百科列表
export function getEncList (params: object) {
  return request({
    url: api.enc.getEncList,
    method: 'get',
    params
  })
}

// 获取课程列表
export function getCourseList (params: object) {
  return request({
    url: api.course.getCourseList,
    method: 'get',
    params
  })
}

// 获取单位列表
export function getCompanyList (params: object) {
  return request({
    url: api.company.list,
    method: 'get',
    params
  })
}

// 获取百科标签
export function getTagList (params: object) {
  return request({
    url: api.enc.tag,
    method: 'get',
    params
  })
}

// 添加百科
export function postEnc (data: object) {
  return request({
    url: api.enc.addEnc,
    method: 'post',
    data
  })
}

// 创建单位
export function creatList (data: object) {
  return request({
    url: api.company.creatList,
    method: 'post',
    data
  })
}

// 查询单位详情
export function getCompanyDes (params: object) {
  return request({
    url: api.company.creatList,
    method: 'get',
    params
  })
}

// 修改单位
export function editListDes (data: object) {
  return request({
    url: api.company.creatList,
    method: 'put',
    data
  })
}

// 删除单位
export function deleteList (params: {id: number}) {
  return request({
    url: api.company.creatList,
    method: 'delete',
    params,
    id: params.id
  })
}

// 获取上传时阿里云临时签名
export function getOssToken (params: object) {
  return request({
    url: api.oss.getOssToken,
    method: 'get',
    params: Object.assign({
      client: 'ind'
    }, params)
  })
}

// 获取单元列表
export function getUnitList () {
  return request({
    url: api.course.getUnitList,
    method: 'get'
  })
}

// 创建课程
export function createCourse (data: object) {
  return request({
    url: api.course.createCourse,
    method: 'post',
    data
  })
}

// 查询课程详情
export function getCourseDes (params: object) {
  return request({
    url: api.course.createCourse,
    method: 'get',
    params
  })
}

// 添加百科ab包
export function encAb (data: object) {
  return request({
    url: api.enc.encAb,
    method: 'post',
    data
  })
}

// 删除百科ab包
export function deleteEncAb (data: {id: number}) {
  return request({
    url: api.enc.encAb,
    method: 'delete',
    data,
    id: data.id
  })
}

// 添加百科资源
export function encResourse (data: object) {
  return request({
    url: api.enc.encResourse,
    method: 'post',
    data
  })
}

// 百科详情
export function bindEncDetail (params: object) {
  return request({
    url: api.enc.bindEncDetail,
    method: 'get',
    params
  })
}

// 查询课件资源
export function getWikiList (params: object) {
  return request({
    url: api.enc.getEncList,
    method: 'get',
    params,
    loading: false
  })
}

// 删除百科
export function deleteEnc (data: {id: number}) {
  return request({
    url: api.enc.deleteEnc,
    method: 'delete',
    data,
    id: data.id
  })
}

// 删除课程
export function deleteCourse (data: {id: number}) {
  return request({
    url: api.course.createCourse,
    method: 'delete',
    data,
    id: data.id
  })
}

// 编辑课程
export function editCourse (data: object) {
  return request({
    url: api.course.createCourse,
    method: 'put',
    data
  })
}

// 修改百科
export function editEnc (data: object) {
  return request({
    url: api.enc.addEnc,
    method: 'put',
    data
  })
}

// 获取百科所属课程
export function getEncCourse (params: object) {
  return request({
    url: api.enc.encCourse,
    method: 'get',
    params
  })
}

// 获取总览
export function getOverview (params: object) {
  return request({
    url: api.company.putOverview,
    method: 'get',
    params
  })
}

// 查询课程所属单位
export function belongSchools (params: object) {
  return request({
    url: api.course.belongSchools,
    method: 'get',
    params
  })
}

// 修改添加总览
export function putOverview (data: object) {
  return request({
    url: api.company.putOverview,
    method: 'put',
    data
  })
}

// 添加总览
export function postOverview (data: object) {
  return request({
    url: api.overview.overviewList,
    method: 'post',
    data
  })
}

// 获取总览
export function overviewList (params: object) {
  return request({
    url: api.overview.overviewList,
    method: 'get',
    params
  })
}
// 总览所属单位
export function getOverviewSchool (params: object) {
  return request({
    url: api.overview.overviewSchool,
    method: 'get',
    params
  })
}

// 绑定总览
export function detailOverview (params: object) {
  return request({
    url: api.overview.detailOverview,
    method: 'get',
    params
  })
}

// 修改总览
export function putOverviewWeb (data: object) {
  return request({
    url: api.overview.overviewList,
    method: 'put',
    data
  })
}

// 删除总览
export function deleteOverview (data: {overview_id: number}) {
  return request({
    url: api.overview.overviewList,
    method: 'delete',
    data,
    id: data.overview_id
  })
}

// 课程下拉列表
export function overviewCourseList (params: object) {
  return request({
    url: api.overview.overviewCourseList,
    method: 'get',
    params
  })
}

// 查询版本列表
export function getVersionList (params: object) {
  return request({
    url: api.version.list,
    method: 'get',
    params
  })
}

// 创建版本
export function createVersion (data: object) {
  return request({
    url: api.version.create,
    method: 'post',
    data
  })
}

// 发布版本
export function publickVersion (data: object) {
  return request({
    url: api.version.publich,
    method: 'put',
    data
  })
}

export function deleteVersion (data: {id: number}) {
  return request({
    url: api.version.create,
    method: 'delete',
    data,
    id: data.id
  })
}

// 查询版本详情
export function getVersionDes (params: object) {
  return request({
    url: api.version.des,
    method: 'get',
    params
  })
}

// 编辑版本
export function editVersion (data: object) {
  return request({
    url: api.version.create,
    method: 'put',
    data
  })
}

// 单位绑定总览
export function bindSchoolOverview (data: object) {
  return request({
    url: api.overview.bindSchoolOverview,
    method: 'put',
    data
  })
}

// 已选百科拖拽排序
export function dragwiki (data: object) {
  return request({
    url: api.course.drag,
    method: 'post',
    data
  })
}

// 绑定工业单位授权
export function schoolPower (params?: object) {
  return request({
    url: api.company.schoolPower,
    method: 'get',
    params
  })
}

// 学校下的管理人下拉
export function schoolMangers (params: object) {
  return request({
    url: api.company.schoolMangers,
    method: 'get',
    params
  })
}

// 添加编辑单位的权限管理
export function putSchoolMangers (data: object) {
  return request({
    url: api.company.schoolPower,
    method: 'put',
    data
  })
}

// 绑定单位的授权课程
export function authCourse (params: object) {
  return request({
    url: api.company.authCourse,
    method: 'get',
    params
  })
}

// 修改工业单位授权的课程
export function batchAuth (data: object) {
  return request({
    url: api.company.batchAuth,
    method: 'post',
    data
  })
}

// 创建习题
export function createExe (data: object) {
  return request({
    url: api.exercise.exerciseHttp,
    method: 'post',
    data
  })
}

// 习题列表
export function exerciseList (params: object) {
  return request({
    url: api.exercise.list,
    method: 'get',
    params
  })
}

// 学习列表
export function fetchSchoolList () {
  return request({
    url: api.company.newList,
    method: 'get'
  })
}

// 获取习题详情
export function fetchExerciseDetail (params: object) {
  return request({
    url: api.exercise.exerciseDetail,
    method: 'get',
    params
  })
}

// 删除习题
export function deleteExerciseList (data: object) {
  return request({
    url: api.exercise.exerciseHttp,
    method: 'delete',
    data
  })
}

// 编辑习题
export function putExercise (data: object) {
  return request({
    url: api.exercise.exerciseHttp,
    method: 'put',
    data
  })
}

// 获取习题详情
export function getExamList (params: object) {
  return request({
    url: api.exam.list,
    method: 'get',
    params
  })
}

// 获取试卷详情
export function fetchExamDetail (params: object) {
  return request({
    url: api.exam.examDetail,
    method: 'get',
    params
  })
}

// 创建试卷
export function createExam (data: object) {
  return request({
    url: api.exam.creat,
    method: 'post',
    data
  })
}

// 删除试卷
export function deleteExamList (data: object) {
  return request({
    url: api.exam.creat,
    method: 'delete',
    data
  })
}

// 编辑试卷选题
export function putExam (data: object) {
  return request({
    url: api.exam.update,
    method: 'put',
    data
  })
}

// 编辑试卷基础信息
export function editExamCom (data: object) {
  return request({
    url: api.exam.creat,
    method: 'put',
    data
  })
}

// 获取用户列表
export function searchUser (params: object) {
  return request({
    url: api.user.searchUser,
    method: 'get',
    params
  })
}

// 用户身份切换
export function updateRoleType (data: object) {
  return request({
    url: api.user.updateRoleType,
    method: 'put',
    data
  })
}

// 删除用户
export function deleteUser (params: object) {
  return request({
    url: api.user.deleteUser,
    method: 'get',
    params
  })
}

// 添加搜索用户
export function addSearchUser (params: object) {
  return request({
    url: api.user.addSearchUser,
    method: 'get',
    params
  })
}

// 添加用户
export function addUser (params: object) {
  return request({
    url: api.user.addUser,
    method: 'get',
    params
  })
}

// 批量注册用户
export function registerStudent (data: object) {
  return request({
    url: api.user.registerStudent,
    method: 'post',
    data
  })
}

// 获取授权码
export function getAuthCode (params: object) {
  return request({
    url: api.activeManger.authCode,
    method: 'get',
    params
  })
}
// 生成授权码
export function generateCode (data: object) {
  return request({
    url: api.activeManger.authCode,
    method: 'post',
    data
  })
}

// 习题分享
export function exerciseShare (data: object) {
  return request({
    url: api.exercise.share,
    method: 'post',
    data
  })
}

// 试卷分享
export function examShare (data: object) {
  return request({
    url: api.exam.share,
    method: 'post',
    data
  })
}

// 习题列表
export function bindCourseSourse (params: object) {
  return request({
    url: api.course.courseSourse,
    method: 'get',
    params
  })
}

// 添加修改资源
export function sourse (data: object) {
  return request({
    url: api.course.sourse,
    method: 'post',
    data
  })
}

// 查询资源
export function getSourse (params: object) {
  return request({
    url: api.enc.encResourse,
    method: 'get',
    params
  })
}

export function useSourse (data: object) {
  return request({
    url: api.course.useSourse,
    method: 'put',
    data
  })
}

// 查询试卷类
export function examTag (params: object) {
  return request({
    url: api.exam.tag,
    method: 'get',
    params
  })
}

// 查询习题类
export function exerciseTag (params: object) {
  return request({
    url: api.exercise.tag,
    method: 'get',
    params
  })
}

// 获取标签列表
export function getTagsList (params: object) {
  return request({
    url: api.tag.list,
    method: 'get',
    params
  })
}

// 添加标签
export function addTagsList (data: object) {
  return request({
    url: api.tag.add,
    method: 'post',
    data
  })
}

// 编辑标签
export function editTagsList (data: object) {
  return request({
    url: api.tag.add,
    method: 'put',
    data
  })
}

// 删除标签
export function deleteTag (data: object) {
  return request({
    url: api.tag.add,
    method: 'delete',
    data
  })
}

// 添加知识点
export function addKnowledgepoint (data: object) {
  return request({
    url: api.enc.knowledgepoint,
    method: 'post',
    data
  })
}

// 知识点
export function initKnowledgepoint (params: object) {
  return request({
    url: api.enc.knowledgepoint,
    method: 'get',
    params
  })
}

// 编辑知识点
export function editKnowledgepoint (data: object) {
  return request({
    url: api.enc.knowledgepoint,
    method: 'put',
    data
  })
}

// 删除知识点
export function deleteKnowledgepoint (data: object) {
  return request({
    url: api.enc.knowledgepoint,
    method: 'delete',
    data
  })
}

// 获取工序卡列表
export function getProcessList (params: object) {
  return request({
    url: api.process.list,
    method: 'get',
    params
  })
}

// 编辑工序卡
export function editProcess (data: object) {
  return request({
    url: api.process.update,
    method: 'put',
    data
  })
}

// 删除工序卡
export function deleteProcess (data: object) {
  return request({
    url: api.process.update,
    method: 'delete',
    data
  })
}

// 创建工序卡
export function createProcess (data: object) {
  return request({
    url: api.process.update,
    method: 'post',
    data
  })
}

// 获取操作票详情
export function getProcessDes (params: object) {
  return request({
    url: api.process.des,
    method: 'get',
    params
  })
}

// 获取操作票列表
export function getTicketList (params: object) {
  return request({
    url: api.ticket.list,
    method: 'get',
    params
  })
}

// 编辑操作票
export function editTicket (data: object) {
  return request({
    url: api.ticket.update,
    method: 'put',
    data
  })
}

// 删除操作票
export function deleteTicket (data: object) {
  return request({
    url: api.ticket.update,
    method: 'delete',
    data
  })
}

// 创建操作票
export function createTicket (data: object) {
  return request({
    url: api.ticket.update,
    method: 'post',
    data
  })
}

// 获取操作票
export function getTicketDes (params: object) {
  return request({
    url: api.ticket.des,
    method: 'get',
    params
  })
}

// 获取班组管理列表
export function getTeamAccountList (params: object) {
  return request({
    url: api.team.account,
    method: 'get',
    params
  })
}

// 获取班组列表
export function getTeamList (params: object) {
  return request({
    url: api.team.list,
    method: 'get',
    params
  })
}

// 获取专业类型
export function getTeamMajor (params: object) {
  return request({
    url: api.team.major,
    method: 'get',
    params
  })
}

// 获取角色类型
export function getTeamRole (params: object) {
  return request({
    url: api.team.role,
    method: 'get',
    params
  })
}

// 创建班组
export function createTeam (data: object) {
  return request({
    url: api.team.update,
    method: 'post',
    data
  })
}

// 编辑班组
export function editTeam (data: object) {
  return request({
    url: api.team.update,
    method: 'put',
    data
  })
}

// 删除班组
export function deleteTeam (data: object) {
  return request({
    url: api.team.update,
    method: 'delete',
    data
  })
}

// 获取单个班信息
export function getTeamDetail (params: object) {
  return request({
    url: api.team.update,
    method: 'get',
    params
  })
}

// 编辑用户所在小组、角色
export function editTeamAccount (data: object) {
  return request({
    url: api.team.account,
    method: 'put',
    data
  })
}

// 创建项目
export function createProject (data: object) {
  return request({
    url: api.project.update,
    method: 'post',
    data
  })
}

// 编辑项目
export function editProject (data: object) {
  return request({
    url: api.project.update,
    method: 'put',
    data
  })
}

// 获取项目列表
export function getProjectList (params: object) {
  return request({
    url: api.project.update,
    method: 'get',
    params
  })
}

// 获取项目详情
export function getProjectDetail (params: object) {
  return request({
    url: api.project.des,
    method: 'get',
    params
  })
}

// 获取项目类型
export function getProjecType (params: object) {
  return request({
    url: api.project.type,
    method: 'get',
    params
  })
}

// 获取项目状态
export function getProjecState (params: object) {
  return request({
    url: api.project.state,
    method: 'get',
    params
  })
}

// 获取项目运行情况
export function getProjecRun (params: object) {
  return request({
    url: api.project.run,
    method: 'get',
    params
  })
}

// 提交审核
export function examineProject(data: object) {
  return request({
    url: api.project.examine,
    method: 'post',
    data
  })
}

// 各环节审核操作
export function checkProject(data: object) {
  return request({
    url: api.project.check,
    method: 'post',
    data
  })
}

export function getAccount(params: object) {
  return request({
    url: api.project.account,
    method: 'get',
    params
  })
}

export function projectLog(params: object) {
  return request({
    url: api.project.projectLog,
    method: 'get',
    params
  })
}

export function projectTeamLog(params: object) {
  return request({
    url: api.project.projectTeamLog,
    method: 'get',
    params
  })
}

// 删除项目
export function deleteProject (data: object) {
  return request({
    url: api.project.update,
    method: 'delete',
    data
  })
}

// 提交审核
export function itemSubmit(data: object) {
  return request({
    url: api.project.itemSubmit,
    method: 'post',
    data
  })
}

// 获取附件
export function projectFiels (params: object) {
  return request({
    url: api.project.fiels,
    method: 'get',
    params
  })
}

// 编辑附件
export function updateFiles(data: object) {
  return request({
    url: api.project.fiels,
    method: 'post',
    data
  })
}

// 编辑项目
export function deleteFiles (data: object) {
  return request({
    url: api.project.fiels,
    method: 'put',
    data
  })
}

// 获取道具
export function getProp (params: object) {
  return request({
    url: api.prop.props,
    method: 'get',
    params
  })
}

// 创建道具
export function createProps(data: object) {
  return request({
    url: api.prop.props,
    method: 'post',
    data
  })
}

// 编辑道具
export function updateProps (data: object) {
  return request({
    url: api.prop.props,
    method: 'put',
    data
  })
}

// 删除道具
export function deleteProp (data: object) {
  return request({
    url: api.prop.props,
    method: 'delete',
    data
  })
}

// 获取场景
export function getScene (params: object) {
  return request({
    url: api.scene.scene,
    method: 'get',
    params
  })
}

// 创建场景
export function createScene(data: object) {
  return request({
    url: api.scene.scene,
    method: 'post',
    data
  })
}

// 编辑场景
export function updateScene (data: object) {
  return request({
    url: api.scene.scene,
    method: 'put',
    data
  })
}

// 删除场景
export function deleteScene (data: object) {
  return request({
    url: api.scene.scene,
    method: 'delete',
    data
  })
}

// 添加操作功能
export function addControl(data: object) {
  return request({
    url: api.prop.control,
    method: 'post',
    data
  })
}

// 添加操作功能
export function addSceneProp(data: object) {
  return request({
    url: api.scene.addProp,
    method: 'post',
    data
  })
}

// 获取场景下所有道具
export function getSceneProp (params: object) {
  return request({
    url: api.scene.addProp,
    method: 'get',
    params
  })
}

// 获取绑定道具
export function bindScene (params: object) {
  return request({
    url: api.scene.bindScene,
    method: 'get',
    params
  })
}
// 道具获取事件
export function getPropsFun(params: object) {
  return request({
    url: api.scene.getProps,
    method: 'get',
    params
  })
}

// 添加流程
export function addProgress(data: object) {
  return request({
    url: api.scene.scene,
    method: 'put',
    data
  })
}

// 加场景-流程
export function addSceneFlow(data: object) {
  return request({
    url: api.scene.flow,
    method: 'post',
    data
  })
}

// 编辑场景-流程
export function editSceneFlow(data: object) {
  return request({
    url: api.scene.flow,
    method: 'put',
    data
  })
}

// 删除场景-流程
export function deleteSceneFlow (data: object) {
  return request({
    url: api.scene.flow,
    method: 'delete',
    data
  })
}

// 获取所有流程
export function getSceneFlow(params: object) {
  return request({
    url: api.scene.mrFlow,
    method: 'get',
    params
  })
}

// 获取绑定某流程信息
export function bindFlowInfo(params: object) {
  return request({
    url: api.scene.bindFlow,
    method: 'get',
    params
  })
}

// 获取道具标签
export function getPropsTags (params: object) {
  return request({
    url: api.prop.tag,
    method: 'get',
    params
  })
}

// 获取角色菜单
export function getRolemenu(params: object) {
  return request({
    url: api.user.rolemenu,
    method: 'get',
    params
  })
}

export function editRolemenu(data: object) {
  return request({
    url: api.user.rolemenu,
    method: 'put',
    data
  })
}

// 获取场景
export function getSceneList (params: object) {
  return request({
    url: api.scene.sceneList,
    method: 'get',
    params
  })
}

// 获取单位下场景
export function getSchoolScene (params: object) {
  return request({
    url: api.scene.schoolScene,
    method: 'get',
    params
  })
}

// 授权场景
export function editSceneAuth(data: object) {
  return request({
    url: api.scene.sceneAuth,
    method: 'put',
    data
  })
}

// 编辑场景发布
export function sceneOverModif (data: object) {
  return request({
    url: api.scene.sceneIsOver,
    method: 'put',
    data
  })
}

// 获取园区列表
export function getParkList (params: object) {
  return request({
    url: api.park.webPark,
    method: 'get',
    params
  })
}

// 获取园区详情
export function getParkDetail (params: object) {
  return request({
    url: api.park.parkDetail,
    method: 'get',
    params
  })
}

// 加园区
export function addPark(data: object) {
  return request({
    url: api.park.webPark,
    method: 'post',
    data
  })
}

// 编辑园区
export function editPark (data: object) {
  return request({
    url: api.park.webPark,
    method: 'put',
    data
  })
}

// 删除园区
export function deletePark (data: object) {
  return request({
    url: api.park.webPark,
    method: 'delete',
    data
  })
}

// 获取课程习题
export function getCourseExercise (params: object) {
  return request({
    url: api.course.courseExercise,
    method: 'get',
    params
  })
}

// 为课程添加习题
export function addCourseExercise (data: object) {
  return request({
    url: api.course.editExercise,
    method: 'put',
    data
  })
}
