import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import Home from '../views/Home.vue'
import Login from '../views/login/Login.vue'
import { getRefreshToken, getToken } from '@/api/cookie'
import store from '@/store/index'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/layout',
    name: 'Layout',
    redirect: '/layout/encyclopediasManger',
    component: () => import('@/views/layout/home-layout.vue'),
    children: [
      {
        path: '/encyclopediasManger',
        name: 'EncyclopediasManger',
        component: () =>
          import('@/views/encyclopediasManger/encyclopediasManger.vue'),
        meta: {
          deepth: 1,
          keepAlive: true
        }
      },
      {
        path: '/courseManger',
        name: 'CourseManger',
        component: () => import('@/views/courseManger/courseManger.vue'),
        meta: {
          deepth: 1,
          keepAlive: true
        }
      },
      {
        path: '/companyManger',
        name: 'CompanyManger',
        component: () => import('@/views/companyManger/companyManger.vue'),
        meta: {
          deepth: 1,
          keepAlive: true
        }
      },
      {
        path: '/editionManger',
        name: 'EditionManger',
        component: () => import('@/views/editionManger/editionManger.vue'),
        meta: {
          deepth: 1,
          keepAlive: true
        }
      },
      {
        path: '/createCompany/:id',
        name: 'CreateCompany',
        props: true,
        component: () => import('@/views/companyManger/createCompany.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/createEnc/:id',
        name: 'CreateEnc',
        props: true,
        component: () =>
          import('@/views/encyclopediasManger/components/createEnc.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/createCourse/:id',
        name: 'CreateCourse',
        props: true,
        component: () => import('@/views/courseManger/createCourse.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/editCourseExercise/:id',
        name: 'editCourseExercise',
        props: true,
        component: () => import('@/views/courseManger/editCourseExercise.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/manger/:id',
        name: 'Manger',
        props: true,
        component: () => import('@/views/companyManger/manger.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/overViewManger',
        name: 'OverViewManger',
        component: () => import('@/views/overViewManger/overViewManger.vue'),
        meta: {
          deepth: 1,
          keepAlive: true
        }
      },
      {
        path: '/createOverview/:id',
        name: 'CreateOverview',
        props: true,
        component: () => import('@/views/overViewManger/createOverview.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/createVersion/:id',
        name: 'createVersion',
        props: true,
        component: () =>
          import('@/views/editionManger/components/createVersion.vue'),
        meta: {
          deepth: 0.5
        }
      },
      {
        path: '/exerciseManager',
        name: 'ExerciseManager',
        component: () => import('@/views/exerciseManager/exerciseManager.vue'),
        meta: {
          deepth: 1,
          keepAlive: true
        }
      },
      {
        path: '/createChoice/:id?',
        name: 'createChoice',
        props: true,
        component: () => import('@/views/exerciseManager/createChoice.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/createMatching/:id?',
        name: 'createMatching',
        props: true,
        component: () => import('@/views/exerciseManager/createMatching.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/createJudge/:id?',
        name: 'createJudge',
        props: true,
        component: () => import('@/views/exerciseManager/createJudge.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/examManager',
        name: 'ExamManager',
        component: () => import('@/views/examManager/examManager.vue'),
        meta: {
          deepth: 1,
          keepAlive: true
        }
      },
      {
        path: '/createExam/:id?',
        name: 'createExam',
        props: true,
        component: () => import('@/views/examManager/createExam.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/editExam/:id',
        name: 'editExam',
        props: true,
        component: () => import('@/views/examManager/editExam.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/userManger',
        name: 'UserManager',
        component: () => import('@/views/userManger/userManger.vue'),
        meta: {
          deepth: 1,
          keepAlive: true
        }
      },
      {
        path: '/activeManger',
        name: 'ActiveManager',
        component: () => import('@/views/activeManger/activeManger.vue'),
        meta: {
          deepth: 0.5
        }
      },
      {
        path: '/resourseEditor/:id',
        name: 'resourseEditor',
        props: true,
        component: () => import('@/views/resourseEditor/resourseEditor.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/soursePage',
        name: 'soursePage',
        component: () => import('@/views/soursePage/soursePage.vue'),
        meta: {
          deepth: 0.5
        }
      },
      {
        path: '/tagManager',
        name: 'TagManager',
        component: () => import('@/views/tagManager/tagManager.vue'),
        meta: {
          deepth: 0.5
        }
      },
      {
        path: '/createPoints/:id',
        name: 'CreatePoints',
        props: true,
        component: () =>
          import('@/views/encyclopediasManger/components/createPoints.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/teamManger',
        name: 'TeamManger',
        component: () => import('@/views/teamManger/teamManger.vue'),
        meta: {
          deepth: 0.5
        }
      },
      {
        path: '/projectManger',
        name: 'ProjectManger',
        component: () => import('@/views/projectManger/projectManger.vue'),
        meta: {
          deepth: 1,
          keepAlive: true
        }
      },
      {
        path: '/processManger',
        name: 'ProcessManger',
        component: () => import('@/views/processManger/processManger.vue'),
        meta: {
          deepth: 1,
          keepAlive: true
        }
      },
      {
        path: '/EditProcess/:id?',
        name: 'EditProcess',
        props: true,
        component: () =>
          import('@/views/processManger/components/createProcess.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/processView/:id?',
        name: 'ProcessView',
        props: true,
        component: () =>
          import('@/views/processManger/components/processView.vue'),
        meta: {
          deepth: 0.5
        }
      },
      {
        path: '/ticketManger',
        name: 'TicketManger',
        component: () => import('@/views/ticketManger/ticketManger.vue'),
        meta: {
          deepth: 1,
          keepAlive: true
        }
      },
      {
        path: '/EditTicket/:id?',
        name: 'EditTicket',
        props: true,
        component: () =>
          import('@/views/ticketManger/components/createItem.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/createProject/:id?',
        name: 'createProject',
        props: true,
        component: () =>
          import('@/views/projectManger/components/createProject.vue'),
        meta: {
          deepth: 0.5
        }
      },
      {
        path: '/projectDetail/:id?',
        name: 'projectDetail',
        props: true,
        component: () =>
          import('@/views/projectManger/components/projectDetail.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/teamDetail/:project_id/:team_id',
        name: 'teamDetail',
        props: true,
        component: () =>
          import('@/views/projectManger/components/teamDetail.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/propManager',
        name: 'PropManager',
        component: () => import('@/views/propManger/propManger.vue'),
        meta: {
          keepAlive: true,
          deepth: 1
        }
      },
      {
        path: '/senceManager',
        name: 'SenceManager',
        component: () => import('@/views/senceManager/senceManager.vue'),
        meta: {
          deepth: 1,
          keepAlive: true
        }
      },
      {
        path: '/createProps/:id',
        name: 'createProps',
        props: true,
        component: () =>
          import('@/views/propManger/components/createProps.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/senceDes/:id',
        name: 'senceDes',
        props: true,
        component: () => import('@/views/senceManager/senceDes.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/createScene/:id',
        name: 'createScene',
        props: true,
        component: () =>
          import('@/views/senceManager/components/createScene.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/createControl/:id',
        name: 'createControl',
        props: true,
        component: () =>
          import('@/views/propManger/components/createControl.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/parkManage',
        name: 'ParkManage',
        component: () => import('@/views/parkManage/parkManage.vue'),
        meta: {
          deepth: 1,
          keepAlive: true
        }
      },
      {
        path: '/createPark/:id',
        name: 'createPark',
        props: true,
        component: () => import('@/views/parkManage/createPark.vue'),
        meta: {
          deepth: 2
        }
      },
      {
        path: '/uploadManger',
        name: 'uploadManger',
        component: () => import('@/views/uploadManger/index.vue'),
        meta: {
          deepth: 1
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (!getRefreshToken() && to.name !== 'Login') {
    router.replace({ name: 'Login' })
  } else {
    next()
  }
})

router.afterEach((to: any, from: any) => {
  if (to.meta.keepAlive) {
    !store.state.includeRouter.includes(to.name) &&
      store.state.includeRouter.push(to.name)
  }
  if ((from.meta.keepAlive && to.meta.deepth < from.meta.deepth) || (to.meta.deepth === from.meta.deepth && to.meta.deepth === 1)) {
    const _index = store.state.includeRouter.indexOf(from.name)
    _index !== -1 && store.state.includeRouter.splice(_index, 1)
  }
  console.log(store.state.includeRouter, 123)
})

export default router
