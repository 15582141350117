
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { GlobalDataProps } from '@/store/index'
export default defineComponent({
  name: 'Loading',
  setup () {
    const store = useStore<GlobalDataProps>()
    const isShow = computed(() => {
      return store.state.loading
    })
    const tip = computed(() => {
      return store.state.loadingObj.tip
    })
    return {
      isShow,
      tip
    }
  }
})
