import { createStore } from 'vuex'
import { login } from '@/api/index'
import routerObj from '@/router/index'
import { setToken, setRefreshToken, setInfo, getToken, removeRefreshToken, removeToken, getInfo } from '@/api/cookie'

// const router = useRouter()
interface UserProps {
  userInfo?: object;
}

export interface GlobalDataProps {
  user: UserProps;
  token: string | undefined;
  loading: boolean;
  loadingObj: any;
  router: any;
  routerList: any[];
  isOfficial: boolean;
  includeRouter: string[];
}
export default createStore<GlobalDataProps>({
  state: {
    user: {},
    token: getToken(),
    loading: false,
    loadingObj: {
      tip: ''
    },
    router: '',
    routerList: sessionStorage.getItem('routerNav') ? JSON.parse(sessionStorage.getItem('routerNav') as any) : [],
    isOfficial: getInfo() ? JSON.parse(getInfo() as string)?.role_id < 71 : false,
    includeRouter: []
  },
  mutations: {
    setIncludeRouter(state, value) {
      debugger
      state.includeRouter = value
    },
    setRouterList(state, value) {
      state.routerList = value
      sessionStorage.setItem('routerNav', JSON.stringify(value))
    },
    login(state, value) {
      state.user = value
    },
    setLoding(state, value) {
      state.loading = value
    },
    setToken(state, res) {
      state.token = res.token
      setToken(res.token)
      setRefreshToken(res.refreshToken)
    },
    saveRouter(state, value) {
      console.log(state, 'state')
      console.log(value, 'value')
      state.router = value
      // localStorage.setItem('router', JSON.stringify(eval(value)))
    },
    goOut: (state) => {
      // if(localStorage.getItem('router')){
      //   console.log(JSON.parse(localStorage.getItem('router')))
      // }
      sessionStorage.clear()
      removeToken()
      removeRefreshToken()
      routerObj.replace({
        name: 'Login'
      })
    },
    setisOfficial: (state) => {
      console.log(JSON.parse(getInfo() as string))
      state.isOfficial = JSON.parse(getInfo() as string)?.role_id < 71
    }
  },
  actions: {
    async fetchLogin({ commit }, obj) {
      try {
        const res = await login(obj)
        console.log('登录成功')
        commit('login', res.data)
        commit('setToken', {
          token: res.data.token.token_type + ' ' + res.data.token.access_token,
          refreshToken: res.data.token.refresh_token
        })
        setInfo(res.data)
        commit('setisOfficial')
      } catch (error) {
        return await Promise.reject(error)
      }
    }
  },
  modules: {
  }
})
