import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { createVNode } from 'vue'
import { Modal } from 'ant-design-vue'
import { h } from '@vue/runtime-core'
/**
 * 提示框
 */
export const showConfirm = (obj: {content: any; callBack?: () => void; cancelBack?: () => void}) => {
  Modal.confirm({
    content: obj.content,
    icon: createVNode(ExclamationCircleOutlined),
    onOk () {
      return new Promise(() => {
        obj.callBack && obj.callBack()
        Modal.destroyAll()
      }).catch(() => {
        console.log('Oops errors!')
        Modal.destroyAll()
      })
    },
    cancelText: '取消',
    okText: '确定',
    onCancel () {
      obj.cancelBack && obj.cancelBack()
      Modal.destroyAll()
    }
  })
}

export const warninConfirm = (time: string) => {
  Modal.info({
    title: '下线通知',
    content: h('div', {}, [
      h('p', `你的账号于 ${time} 在其他地方登录`),
      h('p', '如非本人操作，请尽快修改密码！')
    ]),
    okText: '知道了'
  })
}

/**
 * 对象赋值
 * @param {Object} obj1
 * @param {Object} obj2
 */
export const filterKeys = (obj1: object, obj2: object) => {
  interface ObjProps {
    [key: string]: string;
  }
  Object.keys(obj1).forEach((key: string) => {
    (obj1 as ObjProps)[key] = (obj2 as ObjProps)[key]
  })
}

/**
 * 翻页
 * @param {Object} pagination
 * {total, pageSize, current} = pagination
 */
export const setPage = (pagination: {total: number; pageSize: number; current: number}) => {
  const page = Math.ceil(pagination.total / pagination.pageSize)
  if (page < pagination.current) {
    return page
  } else {
    return pagination.current
  }
}

/**
 * 筛选参数
 * @param {Object} obj
 */
export const screenObj = (obj: object) => {
  interface ObjProps {
    [key: string]: string;
  }
  const _obj = {}
  for (const key in obj) {
    if (typeof ((obj as ObjProps)[key]) === 'boolean' || ((obj as ObjProps)[key] !== undefined && (obj as ObjProps)[key] !== null)) (_obj as ObjProps)[key] = (obj as ObjProps)[key]
  }
  return _obj
}

export const throttle = {
  debounceIdentify: 0,
  bounce(func: any, delay: number) {
    this.debounceIdentify && clearTimeout(this.debounceIdentify)
    this.debounceIdentify = setTimeout(() => {
      func.apply(this)
    }, delay || 300)
  }
}
