import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './assets/styles/reset.css'
import './scss/common.scss'
import './scss/for.scss'

const app = createApp(App)
const checkEmoji = (str: string) => {
  const regx = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ // eslint-disable-line
  if (regx.test(str.trim())) {
    return ''
  }
  return str
}
// 注册一个全局自定义指令 `v-focus`
app.directive('checkEmoji', {
  // 当被绑定的元素挂载到 DOM 中时……
  mounted (el) {
    // 聚焦元素
    const regx = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/g // eslint-disable-line
    el.oninput = function () {
      el.value = el.value.replace(regx, '')
    }
    el.onblur = function () {
      el.value = el.value.replace(regx, '')
    }
  }
})
app.use(store).use(router).use(Antd).mount('#app')
