
import loading from '@/components/Loading.vue'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default defineComponent({
  components: {
    loading
  },
  setup(props) {
    const router = useRouter()
    console.log(router)
    const store = useStore()
    return {
      router,
      store
    }
  }
})
