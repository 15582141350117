export default {
  user: {
    login: '/api/user/login/password', // 登录
    // refreshToken: '/api/identity/token/refresh', // 刷新token
    refreshToken: '/api/user/login/refreshtoken', // 刷新token
    getSitemenu: '/api/base/sitemenu', // 获取菜单
    searchUser: '/api/user/control/account', // 用户管理 [get]
    updateRoleType: '/api/user/role', // 用户身份切换 [put]
    deleteUser: '/api/user/control/delete', // 学校移除用户 [get]
    addSearchUser: '/api/user/control/search', // 搜索用户 [get]
    addUser: '/api/user/control/add', // 学校添加用户 [get]
    registerStudent: '/api/user/control/register/student', // 注册学生用户
    rolemenu: 'api/base/rolemenu'
  },
  company: {
    list: '/api/user/school/search', // 单位列表
    creatList: '/api/user/school', // 创建单位, 查询列表
    putOverview: '/api/arim/school/overview', // 修改添加总览 获取总览
    schoolPower: '/api/user/school/power', // 绑定工业单位授权 编辑单位的权限管理
    schoolMangers: '/api/user/school/managers', // 学校下的管理人下拉
    authCourse: '/api/arim/auth/course', // 绑定单位的授权课程
    batchAuth: '/api/arim/batch/auth', // 修改工业单位授权的课程
    newList: '/api/user/aplus/school' // 学校列表
  },
  enc: {
    getEncList: '/api/arim/encyclopedia/list', // 获取百科列表
    tag: 'api/arim/baike/tag', // 百科标签
    addEnc: 'api/arim/encyclopedia', // 添加百科 修改百科
    deleteEnc: 'api/arim/encyclopedia', // 删除百科
    encCourse: 'api/arim/encyclopedia/belong/courses', // 百科所属课程
    encAb: 'api/arim/encyclopedia/resource', // 添加百科ab包
    encResourse: 'api/arim/personal/resource', // 添加百科资源
    bindEncDetail: 'api/arim/bind/encyclopedia',
    knowledgepoint: 'api/arim/knowledgepoint' // 知识点
  },
  oss: {
    getOssToken: '/api/upload/arim/getosstoken' // 获取上传时阿里云临时签名
  },
  course: {
    getCourseList: '/api/arim/course/page', // 获取课程列表
    getUnitList: '/api/arim/course/category ', // 获取课程标签
    createCourse: '/api/arim/course', // 创建课程
    getWikiList: '/api/arim/encyclopedia/select', // 查询课件资源
    drag: '/api/arim/courseware/select', // 拖拽排序
    belongSchools: '/api/arim/course/belong/schools', // 课程所属单位
    courseSourse: '/api/arim/bind/course/courseware', // 课程资源绑定
    sourse: '/api/arim/course/courseware', // 资源添加修改
    useSourse: '/api/arim/courseware/modifyuse', // 资源是否采用
    courseExercise: '/api/arim/web/course/exercise', // 获取课程习题
    editExercise: '/api/arim/course/exercise' // 编辑随堂课题
  },
  overview: {
    overviewList: '/api/arim/web/overview', // 总览列表 添加总览
    detailOverview: '/api/arim/web/overview/detail', // 绑定总览
    overviewCourseList: '/api/arim/name/search', // 课程下拉列表
    bindSchoolOverview: '/api/arim/school/overview', // 单位绑定总览
    overviewSchool: '/api/user/overview/belong/schools' // 总览所属单位
  },
  version: {
    list: '/api/arim/version/page', // 版本列表
    create: '/api/arim/version', // 创建版本
    des: '/api/arim/versioninfo', // 绑定版本信息
    publich: '/api/arim/publish/version' // 发布正式版本
  },
  exercise: {
    exerciseHttp: '/api/arim/exercise', // 创建编辑删除习题
    list: '/api/arim/exercise/list', // 习题列表
    exerciseDetail: '/api/arim/exercise/detail', // 习题详情
    share: '/api/arim/share/exercise', // 习题分享
    tag: '/api/arim/exercise/tag' // 习题类
  },
  exam: {
    list: '/api/arim/exam/list', // 试卷管理
    examDetail: '/api/arim/exam/detail', // 试卷详情
    creat: '/api/arim/exam', // 创建试卷 编辑试卷
    update: '/api/arim/exam/exercise', // 编辑试卷
    share: '/api/arim/share/exam', // 试卷分享
    tag: '/api/arim/exam/tag' // 试卷类
  },
  activeManger: {
    authCode: '/api/user/school/auth' // 获取授权码
  },
  tag: {
    list: '/api/arim/tag/list', // 获取标签列表
    add: '/api/arim/tag' // 添加标签
  },
  process: {
    list: '/api/task/process/list', // 获取工序卡列表
    update: '/api/task/process', // 编辑工序卡
    des: '/api/task/process/detail' // 获取工序卡详情
  },
  ticket: {
    list: '/api/task/ticket/list', // 获取操作票列表
    update: '/api/task/ticket', // 编辑操作票
    des: '/api/task/ticket/detail' // 获取操作票详情
  },
  team: {
    account: '/api/task/team/account', // 获取班组管理列表 修改用户小组及角色
    list: '/api/task/team/list', // 获取班组列表
    major: '/api/task/major ', // 获取专业类型
    role: '/api/task/role ', // 获取角色类型
    update: '/api/task/team' // 创建编辑班组 获取班组信息 删除班组
  },
  project: {
    update: '/api/task/project', // 创建编辑项目 获取项目列表 删除项目
    des: '/api/task/project/details', // 获取详情
    type: '/api/task/project/type', // 获取项目类型
    state: '/api/task/project/state', // 获取项目状态
    run: '/api/task/project/run', // 获取项目运行情况
    examine: '/api/task/project/submit', // 提交审核
    check: '/api/task/project/check', // 提交审核
    account: '/api/task/account/info', // 获取个人班组角色信息
    projectLog: '/api/task/project/log', // 获取项目运行日志
    projectTeamLog: '/api/task/project/team/log', // 获取项目小组运行日志
    itemSubmit: '/api/task/ticket/item/submit', // 子项勾选提交
    fiels: '/api/task/run/fiels' // 获取运行附件
  },
  prop: {
    props: '/api/arim/mr/prop', // 道具
    control: '/api/arim/mr/prop/control', // 添加操作功能
    tag: 'api/arim/prop/tag' // 获取道具标签
  },
  scene: {
    scene: '/api/arim/mr/scene', // 场景
    addProp: '/api/arim/mr/scene/prop', // 添加道具
    getProps: '/api/arim/mr/prop/bind', // 根据道具获取事件
    bindScene: '/api/arim/mr/scene/bind', // 绑定道具
    flow: '/api/arim/mr/scene/flow', // 场景-流程操作
    mrFlow: '/api/arim/mr/flow', // 获取所有流程
    bindFlow: '/api/arim/mr/flow/bind', // 获取绑定某流程信息
    sceneList: '/api/arim/scene/name', // 下拉列获取显示场景
    schoolScene: '/api/arim/school/scene', // 下拉列获取显示场景
    sceneAuth: '/api/arim/scene/auth', // 授权场景
    sceneIsOver: '/api/arim/mr/scene/isover' // 编辑场景发布
  },
  park: {
    webPark: '/api/arim/web/park', // 园区管理
    parkDetail: '/api/arim/web/park/detail' // 园区详情
  }
}
