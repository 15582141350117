import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "loading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "#loading"
      }, [
        _createVNode("div", _hoisted_1, [
          _createVNode(_component_a_spin, {
            size: "large",
            tip: _ctx.tip
          }, null, 8, ["tip"])
        ])
      ]))
    : _createCommentVNode("", true)
}