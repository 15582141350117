
import { defineComponent, reactive, ref, UnwrapRef, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { GlobalDataProps } from '@/store/index'
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { getMenu } from '@/api/index'
import md5 from 'js-md5'
interface FormState {
  username: string;
  password: string;
}
export default defineComponent({
  name: 'Login',
  components: { UserOutlined, LockOutlined },
  setup () {
    const routerName = ref()
    const isMemberDay = localStorage.getItem('isDay') === '30'
    const isRememberPwd = ref(isMemberDay)
    const formState: UnwrapRef<FormState> = reactive({
      username: '',
      password: ''
    })
    const isValid = (str: string): boolean => {
      return str.trim().length > 0
    }
    const validateUsername = async (rule: RuleObject, value: string) => {
      if (!isValid(value)) {
        return Promise.reject(new Error('请输入用户名'))
      } else if (value.length > 20) {
        return Promise.reject(new Error('账户名长度不能超过20位'))
      }
      return Promise.resolve()
    }
    const validatePassword = async (rule: RuleObject, value: string) => {
      if (!isValid(value)) {
        return Promise.reject(new Error('请输入密码'))
      } else if (value.length < 4 && value.length > 20) {
        return Promise.reject(new Error('密码长度在4-20之间'))
      }
      return Promise.resolve()
    }
    // 表单验证
    const rules = {
      username: [{ required: true, trigger: 'blur', validator: validateUsername, whitespace: true }],
      password: [{ required: true, trigger: 'blur', validator: validatePassword, whitespace: true }]
    }
    const store = useStore<GlobalDataProps>()
    const router = useRouter()
    // 设置密码
    const setPwd = () => {
      if (isRememberPwd.value) {
        const _pwd = formState.password.length === 32 ? formState.password : md5(formState.password)
        localStorage.setItem('n', formState.username)
        localStorage.setItem('p', _pwd)
      } else {
        localStorage.removeItem('n')
        localStorage.removeItem('p')
      }
    }
    const getMenus = () => {
      getMenu({
        // client: 'arim.web'
      }).then((res) => {
        store.commit('setRouterList', res.data)
        routerName.value = res.data[0].href
        router.replace({ name: routerName.value })
      })
    }
    // 提交数据
    const formRef = ref()
    const onSubmit = () => {
      formRef.value.validate().then(() => {
        const _pwd = (localStorage.getItem('n') && formState.password.length === 32) ? formState.password : md5(formState.password)
        store.dispatch('fetchLogin', {
          username: formState.username,
          password: _pwd
        }).then(() => {
          setPwd()
          store.commit('saveRouter', router)
          getMenus()
          // router.replace({ name: routerName.value })
        })
      }).catch((error: ValidateErrorEntity<FormState>) => {
        console.log('error', error)
      })
    }

    // 记住密码
    watch(isRememberPwd, (newValue) => {
      if (newValue) {
        localStorage.setItem('isDay', '30')
      } else {
        localStorage.setItem('isDay', '1')
      }
    })
    // 初始化密码
    const initPwd = () => {
      if (isRememberPwd.value) {
        formState.username = localStorage.getItem('n') as string
        formState.password = localStorage.getItem('p') as string
      }
    }
    onMounted(() => {
      initPwd()
    })

    return {
      formState,
      rules,
      onSubmit,
      formRef,
      isRememberPwd
    }
  }
})
