import Cookies from 'js-cookie'
const TokenKey = 'ind_vue_admin_template_token'
const RefreshTokenKey = 'ind_vue_admin_template_refresh_token'
const UserInfo = 'ind_vue_admin_user_info'

// 存取token
export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token: string) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}
// 存取删refreshToken
export function getRefreshToken () {
  return Cookies.get(RefreshTokenKey)
}

export function setRefreshToken (token: string) {
  const _isDay = localStorage.getItem('isDay') ? Number(localStorage.getItem('isDay')) : 1
  return Cookies.set(RefreshTokenKey, token, {
    expires: _isDay
  })
}

export function removeRefreshToken () {
  return Cookies.remove(RefreshTokenKey)
}
// 存取删个人信息

export function getInfo () {
  return Cookies.get(UserInfo)
}

export function setInfo (token: object) {
  return Cookies.set(UserInfo, JSON.stringify(token))
}

export function removeInfo () {
  return Cookies.remove(UserInfo)
}
